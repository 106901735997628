<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service" class="is-active"
            ><a>Zlecenia serwisowe</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">Zlecenia serwisowe</h2>
          <div class="buttons level-right">
            <a @click.prevent="addNewOrder" class="button is-medium is-dark"
              ><icon name="plus"></icon><span>Dodaj nowe zlecenie</span></a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-3">
        <div class="ym-searchbox">
          <b-input
            ref="searchInput"
            v-model="searchQuery"
            size="is-medium"
            placeholder="SZUKAJ..."
          ></b-input>
          <div
            class="field level level-item level-right ym-searchbox-right"
            v-if="this.searchQuery"
          >
            również nieaktywni
            <b-checkbox
              v-model="searchDeleted"
              size="is-medium"
              true-value="1"
              false-value="0"
              @change.native="toggleSearchDeleted"
            >
            </b-checkbox>
          </div>
        </div>
      </div>
      <div class="column is-9">
        <div class="b-field b-field-flex" style="gap: 8px;">
          <div class="b-field-flex" style="gap: 8px;align-items: center;">
            <TriStateCheckbox
              v-model="filters.is_fv"
              inputId="isFv"
              @input="updateFilter('is_fv', $event)"
            /><label for="isFv" style="cursor: pointer">Faktura</label>
          </div>
          <div class="b-field-flex" style="gap: 8px;align-items: center;">
            <TriStateCheckbox
              v-model="filters.is_paid"
              inputId="isPaid"
              @input="updateFilter('is_paid', $event)"
            /><label for="isPaid" style="cursor: pointer">Płatne</label>
          </div>
          <div class="b-field-flex" style="gap: 8px;align-items: center;">
            <TriStateCheckbox
              v-model="filters.is_warranty"
              inputId="isWarranty"
              @input="updateFilter('is_warranty', $event)"
            /><label for="isWarranty" style="cursor: pointer">Gwarancja</label>
          </div>
          <div class="b-field-flex" style="gap: 8px;align-items: center;">
            <TriStateCheckbox
              v-model="filters.is_np"
              inputId="isNp"
              @input="updateFilter('is_np', $event)"
            /><label for="isNp" style="cursor: pointer">NP</label>
          </div>
          <div class="b-field-flex" style="gap: 8px;align-items: center;">
            <TriStateCheckbox
              v-model="filters.unfinished"
              inputId="isUnfinished"
              @input="updateFilter('unfinished', $event)"
            /><label for="isUnfinished" style="cursor: pointer"
              >Niezakończone</label
            >
          </div>
          <b-datepicker
            :first-day-of-week="1"
            :mobile-native="false"
            @input="searchOrdersFromRange"
            placeholder="Wybierz przedział dat naprawy"
            v-model="dates"
            icon="calendar-today"
            position="is-bottom-left"
            range
          >
          </b-datepicker>
          <div
            v-if="dates.length > 0"
            @click="allOrders"
            class="button is-primary is-medium has-icon"
          >
            <icon name="x"></icon>
          </div>
          <b-select
            @input="searchOrdersFromStatus"
            v-model="status_id"
            placeholder="Wybierz status zlecenia"
            expanded
            style="width: 100%"
          >
            <option :value="null">wszystkie</option>
            <option
              v-for="status in this.$store.state.auth.status"
              :key="status.id"
              :value="status.id"
              :style="`color: ${status.colour}`"
              >{{ status.name }}</option
            >
          </b-select>
          <div
            v-if="isSearch"
            @click="cleanSearch"
            class="button is-black is-medium has-icon"
            style="margin-left: 16px"
          >
            <icon name="x"></icon> Usuń filtry
          </div>
        </div>
      </div>
      <div class="column is-12">
        <b-table
          :data="orders"
          :row-class="rowClassChecker"
          paginated
          backend-pagination
          :total="total"
          :current-page="page"
          :per-page="perPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column
            field="number"
            label="Numer zlecenia"
            width="8%"
            sortable
            v-slot="props"
          >
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.number }}</router-link
            >
          </b-table-column>
          <b-table-column
            field="customer_name"
            width="12%"
            label="Klient"
            v-slot="props"
          >
            <router-link
              tag="a"
              class="text-ellipsis"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              style="max-width: 200px"
              >{{ props.row.customer_shortname }}</router-link
            >
          </b-table-column>
          <!--
          <b-table-column field="customer_phone" label="Telefon klienta"  v-slot="props">
            <router-link tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.customer_phone }}</router-link>
          </b-table-column> -->
          <b-table-column field="device_type" label="Maszyna" v-slot="props">
            <div class="flex-gap">
              <span v-if="props.row.device_is_rental" class="rental">R</span>
              <router-link
                tag="a"
                class="text-ellipsis"
                :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
                >{{ props.row.device_type }} /
                {{ props.row.device_name }}</router-link
              >
            </div>
          </b-table-column>
          <!--
            <b-table-column field="device_address" label="Miejsce postoju maszyny"  v-slot="props">
              <router-link tag="a" class="text-ellipsis" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.device_address }}</router-link>
            </b-table-column>
          -->
          <b-table-column
            field="repared_at"
            label="Data naprawy"
            v-slot="props"
          >
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
            >
              {{ props.row.repared_at }}
              <small
                v-if="
                  props.row.repared_at &&
                    new Date(props.row.repared_at).getDay() === 6
                "
                style="font-weight: bold;margin-left: 4px;"
                >(sobota)</small
              >
              <small
                v-if="
                  props.row.repared_at &&
                    new Date(props.row.repared_at).getDay() === 0
                "
                style="font-weight: bold;margin-left: 4px;"
                >(niedziela)</small
              >
            </router-link>
          </b-table-column>
          <b-table-column label="Serwisant" v-slot="props">
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.technical_name }}</router-link
            >
          </b-table-column>
          <b-table-column label="Status" v-slot="props">
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              ><small :style="statusColor(props.row.status_id)">{{
                statusName(props.row.status_id)
              }}</small></router-link
            >
          </b-table-column>
          <b-table-column label="Faktura" field="is_fv" sortable v-slot="props">
            <span :style="props.row.is_fv ? 'color: green' : ''">{{
              props.row.is_fv ? "TAK" : "NIE"
            }}</span>
          </b-table-column>
          <b-table-column
            label="Płatne"
            field="is_paid"
            sortable
            v-slot="props"
          >
            <span :style="props.row.is_paid ? 'color: green' : ''">{{
              props.row.is_paid ? "TAK" : "NIE"
            }}</span>
          </b-table-column>
          <b-table-column
            label="Gwarancja"
            field="is_warranty"
            sortable
            v-slot="props"
          >
            <span :style="props.row.is_warranty ? 'color: green' : ''">{{
              props.row.is_warranty ? "TAK" : "NIE"
            }}</span>
          </b-table-column>
          <b-table-column label="NP" field="is_np" sortable v-slot="props">
            <span :style="props.row.is_np ? 'color: green' : ''">{{
              props.row.is_np ? "TAK" : "NIE"
            }}</span>
          </b-table-column>
          <b-table-column
            label="Status gwarancji"
            field="warranty_status_id"
            sortable
            v-slot="props"
          >
            {{ warrantyStatusName(props.row.warranty_status_id) }}
          </b-table-column>
          <b-table-column field="" label="" v-slot="props">
            <div
              v-if="!props.row.operator_confirmed && props.row.status_id === 10"
              class="buttonWrap"
            >
              <span
                class="button is-small40"
                :style="statusColor(props.row.status_id)"
                @click="confirmTheService(props.row)"
                >Potwierdz zlecenie</span
              >
            </div>
            <div
              v-else-if="
                !props.row.operator_confirmed &&
                  props.row.operator_id === $store.state.auth.user.id
              "
              class="buttonWrap"
            >
              <span
                class="button is-small40"
                :style="statusColor(props.row.status_id)"
                @click="confirmTheService(props.row)"
                >Potwierdz zlecenie</span
              >
            </div>
            <div v-else class="buttonWrap">
              <a
                :href="props.row.pdf_order"
                target="_blank"
                class="button is-small40"
                ><div>PDF</div></a
              >
              <span
                v-if="props.row.is_active === true"
                class="button is-small40"
                @click="orderRemove(props.row.id)"
                ><div>Usuń</div></span
              >
            </div>
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div
                class="content"
                style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;"
                v-if="!loading"
              >
                <p>
                  <i
                    class="mdi mdi-magnify mdi-24px"
                    style="position: relative; top: 4px; margin-right: 16px;"
                  ></i>
                  Brak wyników wyszukiwania.
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";
import TriStateCheckbox from "@/components/TriStateCheckbox.vue";

export default {
  name: "Service",
  components: {
    FixedHeader,
    TriStateCheckbox,
  },
  data() {
    return {
      warrantyStatus: [],
      serviceType: [],
      orders: [],
      loading: false,
      total: 0,
      sortField: "number",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      page: this.$store.state.records.page,
      perPage: 100,
      selected: null,
      //   filters: {
      //     is_fv: 0,
      //     is_paid: 0,
      //     is_warranty: 0,
      //     is_service: 0,
      //     is_trade: 0,
      //     is_np: 0,
      //     unfinished: 0,
      //   },
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
    filters: {
      handler() {
        this.debouncedSearchQuery();
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.searchInput.$el.children[0].focus();
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getOrders, 500);
    this.getOrders();
    this.getServiceType();
    this.getWarrantyStatus();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.getElementById("active-service-type")?.remove();
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    isSearch() {
      return this.searchQuery !== "" ||
        this.searchDeleted !== "0" ||
        this.status_id !== null ||
        this.dates.length > 0
        ? true
        : false ||
            Object.keys(this.filters).find((i) => this.filters[i] === 1) ||
            Object.keys(this.filters).find((i) => this.filters[i] === 2);
    },
    filters() {
      return this.$store.state.records.filters;
    },
    searchQuery: {
      get() {
        return this.$store.state.records.searchQuery;
      },
      set(value) {
        let payload = {
          category: "searchQuery",
          value,
        };
        this.search(payload);
      },
    },
    searchDeleted: {
      get() {
        return this.$store.state.records.searchDeleted;
      },
      set(value) {
        let payload = {
          category: "searchDeleted",
          value,
        };
        this.search(payload);
      },
    },
    status_id: {
      get() {
        return this.$store.state.records.status_id;
      },
      set(value) {
        let payload = {
          category: "status_id",
          value,
        };
        this.search(payload);
      },
    },
    dates: {
      get() {
        let dates = [];
        for (const date of this.$store.state.records.dates) {
          dates.push(new Date(date));
        }
        return dates;
      },
      set(value) {
        let payload = {
          category: "dates",
          value,
        };
        this.search(payload);
      },
    },
    trows() {
      return document.querySelector("table").rows;
    },
    repared_at_from() {
      return this.dates[0];
    },
    repared_at_to() {
      return this.dates[1];
    },
  },
  methods: {
    ...mapActions({
      getOrdersCall: "orders/getOrders",
      removeOrderCall: "orders/removeOrder",
      confirmTheServiceCall: "orders/confirmOrder",
      search: "records/search",
      getServiceTypeCall: "orders/serviceType",
      getWarrantyStatusCall: "orders/warrantyStatus",
    }),
    updateFilter(key, value) {
      const newFilters = { ...this.filters, [key]: value };
      this.$store.dispatch("records/search", {
        category: "searchFilters",
        value: newFilters,
      });
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrders() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        repared_at_from,
        repared_at_to,
        status_id,
        filters,
      } = this;

      this.getOrdersCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        repared_at_from,
        repared_at_to,
        status_id,
        filters,
      })
        .then((resp) => {
          this.total = resp.page.items;
          if (this.total <= this.perPage) {
            document.querySelector(".top .pagination").style.display = "none";
          }
          this.orders = resp.data;
        })
        .catch((error) => {
          this.orders = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    orderRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrders();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    onPageChange(page) {
      this.page = page;
      let payload = {
        category: "page",
        value: page,
      };
      this.search(payload);
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getOrders();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getOrders();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted

        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          if (gethighlight && trow.className === "is-selected") {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.className = "normal";
          }
        } //end while

        return gethighlight
          ? null
          : (this.className =
              this.className === "is-selected" ? "normal" : "is-selected");
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    addNewOrder() {
      Promise.all([
        this.$store.commit("orders/setOrder", {}),
        this.$store.commit("orders/setCustomer", {}),
        this.$store.commit("orders/setTechnician", {}),
        this.$store.commit("orders/setDevice", {}),
      ]).then(() => this.$router.push({ name: "ServiceAdd" }));
    },
    searchOrdersFromRange() {
      this.getOrders();
    },
    searchOrdersFromStatus() {
      this.getOrders();
    },
    allOrders() {
      this.dates = [];
      this.getOrders();
    },
    confirmTheService(order) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz potwierdzić zlecenie?",
          content: ``,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "check",
          submit: `<span class="mal50i">Potwierdzam</span>`,
          ymConfirmClass: "button is-medium is-primary",
          ymConfirm: true,
          ymOnConfirm: () => {
            this.confirmTheServiceCall({ order, id: order.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrders();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    statusName: function(id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return status[0].name;
      }
    },
    statusColor: function(id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    cleanSearch() {
      this.searchQuery = "";
      this.searchDeleted = "0";
      this.status_id = null;
      this.dates = [];
      this.filters.is_fv = 0;
      this.filters.is_paid = 0;
      this.filters.is_warranty = 0;
      this.filters.is_service = 0;
      this.filters.is_trade = 0;
      this.filters.is_np = 0;
      this.filters.unfinished = 0;
      //   this.getOrders();
    },
    rowClassChecker(row) {
      if (
        row.technical_id === 45 ||
        row.technical_id === 48 ||
        row.operator_id === 49 ||
        row.operator_id === 54
      ) {
        return "is-info";
      } else if (row.service_type_id !== 0) {
        return `service-type service-type-${row.service_type_id}`;
      }
    },
    getServiceType() {
      this.getServiceTypeCall()
        .then((resp) => (this.serviceType = resp))
        .then(() => {
          const activeServiceType = this.serviceType.filter(
            (item) => item.is_active
          );
          const styles = [];
          for (let sType of activeServiceType) {
            const style = `.service-type-${sType.id} {background-color: ${sType.colour};}`;
            styles.push(style);
          }
          document.head.insertAdjacentHTML(
            "beforeend",
            `<style id="active-service-type">${styles.join("")}</style>`
          );
        })
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    warrantyStatusName(id) {
      const status = this.warrantyStatus.find((item) => item.id === id);
      return id === 0 ? "-" : status.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.ping {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
<style lang="scss">
tr.is-info {
  background: #7dbbff;
  color: #fff;
}
table.table tbody tr.is-info td,
.b-table .table tbody tr.is-info td,
table.table tbody tr.is-info td a,
table.table tbody tr.service-type-3 td,
.b-table .table tbody tr.service-type-3 td,
table.table tbody tr.service-type-3 td a {
  color: #fff;
}
table.table tbody tr.is-info:hover td,
.b-table .table tbody tr.is-info:hover td,
table.table tbody tr.is-info:hover td a,
table.table tbody tr.service-type-3:hover td,
.b-table .table tbody tr.service-type-3:hover td,
table.table tbody tr.service-type-3:hover td a {
  color: #000;
}
table.table tbody tr.is-info td a.button,
table.table tbody tr.service-type-3 td a.button {
  color: initial;
}
</style>
